import React, {useState, useEffect} from 'react';
import {
  CircularProgress,
  Card,
  List,
  ListItem,
  Divider,
  IconButton,
  Button,
  TextField,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import { useAuth0 } from "../../react-auth0-wrapper";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import InvoiceMaterialDropdown from "../Invoice/InvoiceMaterialDropdown";
import InvoiceMaterialUnitsDropdown from "../Invoice/InvoiceMaterialUnitDropdown";
import ListSubheader from '@material-ui/core/ListSubheader';
import { config } from "../../config";

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    width: '100%'

  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export default function AddMaterialsDialog(props) {
  const {open, setOpen, getOldMaterials} = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [materiallist, setMaterialList] = useState([]);
  const { isAuthenticated, loginWithRedirect, logout, user, getTokenSilently } = useAuth0();
  const [permissionType, setPermissionType] = useState([]);
  const [inSubmit, setInSubmit] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const getPermissions = async () => {
      try {
        const token = await getTokenSilently();
        const response = await fetch(config.API_ENDPOINT + "/api/getUserPermissions", {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
  
        const responseData = await response.json();
        setPermissionType(responseData);
      }
      catch (error) {
        console.error(error);
      }
    };
    getPermissions();
  }, [getTokenSilently, user])

  const changeChoice = (choice, item) => {
    props.setMaterialList([...props.materialList.map(material => {
        if(material !== item)
        {
          return material;
        }
        else
        {
          material.basePrice = choice.fieldData.PurchaseService_Base_c;
          material.direct_cost = choice.fieldData.PurchaseService_Base_c;
          material.price = choice.fieldData.PurchaseSalePrice_c;

          if (material.direct_cost !== '' && material.price !== '') {
            var margin = ((material.price - material.direct_cost) / material.price) * 100;
            material.margin = parseFloat(margin).toFixed(2);
          } else {
            material.margin = '';
          }

          material.units = choice.fieldData.Unit;
          return material;
        }
      })
    ]);
  };

  const handleSave = async () => {
    setInSubmit(true);

    const token = await getTokenSilently()
    await fetch(
      config.API_ENDPOINT + '/api/updateJobMaterials',
      {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json', // sent request
          Accept: 'application/json' // expected data sent back
        },
        body: JSON.stringify({ jobId: props.jobId, oldMaterials: props.oldMaterials, newMaterials: props.materialList })
      }
    );

    await getOldMaterials();

    setInSubmit(false);
  };

  useEffect(() => {
    const getMaterials = async () => {
      try {
        const token = await getTokenSilently();
        const response = await fetch(config.API_ENDPOINT + "/api/getMaterials", {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',  // sent request
            'Accept':       'application/json'   // expected data sent back
          },
          body: JSON.stringify({id: props.jobId})
        });
  
        const responseData = await response.json();
        if(responseData.message)
        {
          setMaterialList(responseData.data.response.data)
          setLoading(false);
          
          //setJobDetails(responseData.data.response.data[0].fieldData);
          //setNotes(responseData.data.response.data[0].fieldData.TechNotes)
        }
        else
        {
            setLoading(false);
          //alert(responseData);
        }

      } catch (error) {
        console.error(error);
      }
    };

    getMaterials();
  }, [getTokenSilently, props.jobId]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Add Materials</DialogTitle>
        <DialogContent>
          <div style={{width:'100%', display: 'flex', textAlign: 'center', justifyContent:'center', flexDirection:'column', borderRadius: '0px'}}>
            {!loading &&
                <Card style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection:'column', borderRadius: '0px'}}>
                    <List
                      subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                          Enter the materials used for this job
                        </ListSubheader>
                      }
                    >
                      
                      {props.materialList.map((item, i) => {
                        return (
                          <div key={i} >
                          <ListItem style={{width:'100%', display: 'flex', textAlign: 'center', flexDirection:'column', borderRadius: '0px'}} key={i}>
                            <Grid container spacing={1}>
                              <Grid item xs={4} sm={7}>
                              <select defaultValue={item.name} onChange={(event) =>
                                    {
                                      event.persist();
                                    props.setMaterialList([...props.materialList.map(material => {
                                        if(material !== item)
                                        {
                                          return material;
                                        }
                                        else
                                        {
                                          material.name = event.target.value;
                                          return material;
                                        }
                                      })
                                    ])
                                  }}>

                                {materiallist.map((option) => ( //Rennel Parino HPDS 7/31/2024 - Debug how to set onChange event for select
                                // Debug:  How it is set up right now is that it is changing all dropdowns to the same selected value.
                                // Variable needs to be set locally per select instance (unsure how to do this in React)

                                  <option value={option.fieldData.Item}>{option.fieldData.Item}</option>

                                ))}

                              </select>
                                <InvoiceMaterialDropdown changeValue={(value) => {
                                  props.setMaterialList([...props.materialList.map(material => {
                                      if(material !== item)
                                      {
                                        return material;
                                      }
                                      else
                                      {
                                        material.name = value;
                                        return material;
                                      }
                                    })
                                  ])
                                }} value={item.name} changeChoice={(choice) => { changeChoice(choice, item)}} materials={materiallist} />
                              </Grid>
                              <Grid item xs={3} sm={2}>
                                <TextField
                                  style={{width: '100%'}}
                                  label="Quantity"
                                  onChange={(event) =>
                                    {
                                      event.persist();
                                    props.setMaterialList([...props.materialList.map(material => {
                                        if(material !== item)
                                        {
                                          return material;
                                        }
                                        else
                                        {
                                          material.qty = event.target.value;
                                          return material;
                                        }
                                      })
                                    ])
                                  }}
                                  value={item.qty}
                                >

                                </TextField>

                              </Grid>
                              <Grid item xs={3} sm={2}>
                                <InvoiceMaterialUnitsDropdown
                                  changeValue={(value) => {
                                    props.setMaterialList([...props.materialList.map(material => {
                                        if(material !== item)
                                        {
                                          return material;
                                        }
                                        else
                                        {
                                          material.units = value;
                                          return material;
                                        }
                                      })
                                    ])
                                  }}
                                  value={item.units}
                                  changeChoice={() => {}}
                                  materials={[...new Set(materiallist.map(material => { return material.fieldData.Unit }))]}
                                  label="Units"
                                  placeholder="Units"
                                />
                              </Grid>
                              <Grid item xs={2} sm={1}>
                                <IconButton onClick={(item) => { props.setMaterialList(
                                  props.materialList.filter((val, k) => { return k !== i;})
                                )}}>
                                  <DeleteForeverIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                            {/*<Grid container spacing={2}>
                              {/*
                              <Grid item xs={4}>
                            <TextField
                                style={{width: '100%'}}
                                label="Price"
                                onChange={(event) =>
                                  {
                                    event.persist();
                                  props.setMaterialList(prevState => {
                                    return [...prevState.map(material => {
                                      if(material !== item)
                                      {
                                        return material;
                                      }
                                      else
                                      {
                                        material.price = event.target.value;
                                        return material;
                                      }
                                    })];
                                  }
                                )}}
                                value={item.price}
                              >

                              </TextField>
                            </Grid>
                                */}
                            {/*<Grid item xs={permissionType.includes("type:admin") ? 6 : 6}>
                              {/*<TextField
                                style={{width: '100%'}}
                                label="Unit"
                                onChange={(event) =>
                                  {
                                    event.persist();
                                  props.setMaterialList(prevState => {
                                    return [...prevState.map(material => {
                                      if(material !== item)
                                      {
                                        return material;
                                      }
                                      else
                                      {
                                        material.units = event.target.value;
                                        return material;
                                      }
                                    })];
                                  }
                                )}}
                                value={item.units}
                              >

                              </TextField>*/}

                                {/*<InvoiceMaterialUnitsDropdown
                                  changeValue={(value) => {
                                    props.setMaterialList(prevState => {
                                      return [...prevState.map(material => {
                                        if(material !== item)
                                        {
                                          return material;
                                        }
                                        else
                                        {
                                          material.units = value;
                                          return material;
                                        }
                                      })];
                                    })
                                  }}
                                  value={item.units}
                                  changeChoice={() => {}}
                                  materials={[...new Set(materiallist.map(material => { return material.fieldData.Unit }))]}
                                  label="Units"
                                  placeholder="Units"
                                />

                              {/*
                              <FormControl variant="outlined" className={classes.formControl}>
                                <InputLabel htmlFor="outlined-age-simple">
                                  Unit
                                </InputLabel>
                                <Select
                                  value={item.units}
                                  onChange={(event) =>
                                  {
                                      event.persist();
                                    props.setMaterialList(prevState => {
                                      return [...prevState.map(material => {
                                        if(material !== item)
                                        {
                                          return material;
                                        }
                                        else
                                        {
                                          material.units = event.target.value;
                                          return material;
                                        }
                                      })];
                                    });
                                  }}
                                  inputProps={{
                                    name: 'age',
                                    id: 'outlined-age-simple',
                                  }}
                                >
                                  
                                  {[...new Set(materiallist.map(material => { return material.fieldData.Unit }))].map((material) => <MenuItem key={material} value={material}>
                                    {material}
                                  </MenuItem>)}
                                </Select>
                              </FormControl>*/}
                            {/*</Grid>
                            {/*permissionType.includes("type:admin") &&
                            <Grid item xs={4}>
                              <TextField
                                style={{width: '100%'}}
                                label="Price"
                                onChange={(event) =>
                                  {
                                    event.persist();
                                  props.setMaterialList(prevState => {
                                    return [...prevState.map(material => {
                                      if(material !== item)
                                      {
                                        return material;
                                      }
                                      else
                                      {
                                        material.price = event.target.value;
                                        return material;
                                      }
                                    })];
                                  }
                                )}}
                                value={item.price}
                              >

                              </TextField>

                            </Grid>
                                */}
                            {/*<Grid item xs={permissionType.includes("type:admin") ? 6 : 6}>
                            <TextField
                              style={{width: '100%'}}
                              label="Quantity"
                              onChange={(event) =>
                                {
                                  event.persist();
                                props.setMaterialList(prevState => {
                                  return [...prevState.map(material => {
                                    if(material !== item)
                                    {
                                      return material;
                                    }
                                    else
                                    {
                                      material.qty = event.target.value;
                                      return material;
                                    }
                                  })];
                                }
                              )}}
                              value={item.qty}
                            >

                            </TextField>

                          </Grid>
                              </Grid>*/}
                          </ListItem>
                          <Divider />
                              
                          </div>
                          );
                      })}
                      {/*permissionType.includes("type:admin") &&
                      <ListItem style={{textAlign:'right', height: '200px'}}><ListItemText primary={"Material Total: $" + props.materialList.reduce((a,b) => {
                        return (parseFloat(a) + ((b === 0 ? 0 : parseFloat(b.price === "" ? 0 : b.price.toString().replace("$", ""))) * (b === 0 ? 0 : parseFloat(b.qty === "" ? 0 : b.qty.toString().replace("$", "")))))
                        }, 0 ).toFixed(2)}
                      />
                      </ListItem>
                      */}

                      {/*<ListItem style={{height: '25px'}} />*/}
                  </List>
                  <Button style={{margin:'25px'}} onClick={() => {
                    props.setMaterialList([...props.materialList, {name:"", price:"", qty: "", units: ""}])
                  }} variant="contained" color="primary">Add Row</Button>
                </Card>
            }
            {loading && <div style={{width:'100%', display: 'flex', textAlign: 'center', justifyContent:'center', borderRadius: '0px'}}><CircularProgress></CircularProgress></div>}
          </div>

        </DialogContent>
        {inSubmit && <div style={{width:'100%', display: 'flex', textAlign: 'center', justifyContent:'center', borderRadius: '0px'}}><CircularProgress></CircularProgress></div>}
        {!inSubmit &&
          <DialogActions>
            <Button onClick={handleSave} color="primary">
              Save
            </Button>
            <Button onClick={handleClose}>
              Close
            </Button>
          </DialogActions>
        }
      </Dialog>
    </div>
  );
}