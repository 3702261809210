import React, { useState, useEffect } from 'react'
import { useAuth0 } from '../../react-auth0-wrapper'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CircularProgress from '@material-ui/core/CircularProgress'
import IconButton from '@material-ui/core/IconButton'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import Avatar from '@material-ui/core/Avatar'
import _ from 'lodash'
import { FormControl } from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { config } from '../../config'

const useStyles = makeStyles(theme => ({}))

export default function JobTechs (props) {
  const [contentLoading, setContentLoading] = useState(true)
  const classes = useStyles()
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    getTokenSilently,
    user
  } = useAuth0()
  const [techList, setTechList] = useState([])
  const [allTechList, setAllTechList] = useState([])
  const [newTech, setNewTech] = useState(false)
  const [permissionType, setPermissionType] = useState([])

  useEffect(() => {
    const callApi = async () => {
      try {
        const token = await getTokenSilently()
        const response = await fetch(config.API_ENDPOINT + '/api/getJobTechs', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // sent request
            Accept: 'application/json' // expected data sent back
          },
          body: JSON.stringify({ jobId: props.location.state.__ID })
        })
        const responseData = await response.json()
        if (responseData.message) {
          setTechList(responseData.data)

          setContentLoading(false)
        } else {
        }
      } catch (error) {
        console.error(error)
      }
    }
    callApi()
  }, [getTokenSilently, props.location.state.__ID])

  useEffect(() => {
    const callApi = async () => {
      try {
        const token = await getTokenSilently()
        const response = await fetch(config.API_ENDPOINT + '/api/getAllTechs', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // sent request
            Accept: 'application/json' // expected data sent back
          }
        })
        const responseData = await response.json()
        if (responseData.message) {
          setAllTechList(responseData.data)
        } else {
        }
      } catch (error) {
        console.error(error)
      }
    }
    callApi()
  }, [getTokenSilently, props.location.state.__ID])

  const handleNewTech = (action, tech, techs) => {
    let staff_id = tech.fieldData.__ID
    let job_id = props.location.state.__ID

    setNewTech(false)
    setContentLoading(true)

    const callApi = async () => {
      try {
        const token = await getTokenSilently()
        const response = await fetch(config.API_ENDPOINT + `/api/setJobTechs/`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // sent request
            Accept: 'application/json' // expected data sent back
          },
          body: JSON.stringify({ jobId: job_id, techs: techs })
        })
        const responseData = await response.json()
        if (responseData.message) {
          //Send a text notification if this tech was just added to the job
          if (action === 'add') {
            sendJobNotification(staff_id, job_id)
            setDispatcherDetails(staff_id, job_id)
          }
          
          setTechList(responseData.data)

          setContentLoading(false)
        } else {
          setContentLoading(false)
        }
      } catch (error) {
        console.error(error)
      }
    }

    callApi()
  }

  const sendJobNotification = async (staff_id, job_id) => {
    try {
      const token = await getTokenSilently()
      const response = await fetch(
        config.API_ENDPOINT + '/api/sendJobNotification',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // sent request
            Accept: 'application/json' // expected data sent back
          },
          body: JSON.stringify({ staffId: staff_id, jobId: job_id })
        }
      )
      const responseData = await response.json()
      if (responseData.message) {
        console.log('notification message A')
        console.log(responseData.message)
      } else {
        console.log('notification message B')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const setDispatcherDetails = async (staff_id, job_id) => {
  console.log('setDispatcherDetails ran')
    try {
      const token = await getTokenSilently()
      const response = await fetch(
        config.API_ENDPOINT + '/api/setDispatcherData',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json', // sent request
            Accept: 'application/json' // expected data sent back
          },
          body: JSON.stringify({ staffId: staff_id, jobId: job_id })
        }
      )
      const responseData = await response.json()
      if (responseData.message) {
        console.log('notification2 message A')
        console.log(responseData.message)
      } else {
        console.log('notification2 message B')
      }
    } catch (error) {
      console.error(error)
    }
  }

  const deleteTech = tech => {
    let newTechList = techList.filter(currentTech => {
      return tech.fieldData.__ID !== currentTech.fieldData.__ID
    })

    handleNewTech('delete', tech, newTechList)
  }

  //Event handler for when a new tech is selected
  //Adds the new tech to the list and saves in DB
  const handleChange = event => {
    let newTechList = techList
    let tech = event.target.value

    //add new tech to list
    newTechList.push(tech)

    //save in DB
    handleNewTech('add', tech, newTechList)
  }

  useEffect(() => {
    const getPermissions = async () => {
      try {
        const token = await getTokenSilently()
        const response = await fetch(
          config.API_ENDPOINT + '/api/getUserPermissions',
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        )

        const responseData = await response.json()
        setPermissionType(responseData)
      } catch (error) {
        console.error(error)
      }
    }
    getPermissions()
  }, [getTokenSilently, user])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '99%'
      }}
    >
      {!contentLoading && (
        <>
          <Card
            style={{
              width: '100%',
              display: 'flex',
              textAlign: 'center',
              flexDirection: 'column',
              border: '2px solid #eaeaea'
            }}
          >
            {/* List of techs currently assigned to this job */}
            <List>
              <ListItem>
                <p
                  style={{
                    color: 'rgb(0, 0, 0)',
                    fontWeight: '500',
                    fontSize: '16px',
                    margin: '0',
                    marginBottom: '8px',
                    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                  }}
                >
                  Techs Assigned to Job
                </p>
                {/* <ListItemText style={{ color: 'rgb(0, 0, 0)', fontWeight: '500', fontSize: '20px', marginBottom: '2px'}} primary="Techs Assigned Job" /> */}
              </ListItem>
              {techList.map(tech => {
                return (
                  <div key={tech.fieldData.__ID}>
                    <Divider />
                    <ListItem button>
                      <ListItemAvatar>
                        <Avatar
                          src={'Auth0' in tech ? tech.Auth0.picture : ''}
                        />
                      </ListItemAvatar>
                      <ListItemText
                        primary={tech.fieldData.Name_combined_ae}
                      ></ListItemText>
                      <IconButton
                        onClick={() => {
                          deleteTech(tech)
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </ListItem>
                  </div>
                )
              })}

              <Divider />

              {/* Select drop down list of techs */}
              <ListItem>
                <FormControl style={{ width: '100%' }}>
                  {/* <InputLabel style={{ color: 'rgb(0, 0, 0)', fontWeight: '500', fontSize: '16px', marginBottom: '2px'}}>Add Tech</InputLabel> */}
                  <p
                    style={{
                      color: 'rgb(0, 0, 0)',
                      fontWeight: '500',
                      fontSize: '16px',
                      marginBottom: '2px',
                      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
                    }}
                  >
                    Add Tech
                  </p>

                  <Select
                    style={{ minHeight: '49px', maxWidth: '100%' }}
                    onChange={handleChange}
                    value={''}
                    displayEmpty={true}
                  >
                    {/*
                    
                    HPDS Rennel Parino 9/21/2023
                    To add back the HPDS names, remove these lines (291-292):
                      .filter(tech => tech.fieldData.Role != 'HPDS Developer')
                      .filter(tech => tech.fieldData.Role != 'HPD Developer')
                       */
                      allTechList
                      .filter(tech => tech.fieldData.ActiveStatus_ae == 'Active')
                      .filter(tech => tech.fieldData.Role != 'HPDS Developer')
                      .filter(tech => tech.fieldData.Role != 'HPD Developer')
                      .filter(
                        tech =>
                          !techList
                            .map(currentTech => currentTech.fieldData.__ID)
                            .includes(tech.fieldData.__ID)
                      )
                      .map(tech => (
                        <MenuItem key={tech.fieldData.__ID} value={tech}>
                          <ListItemAvatar>
                            <Avatar
                              src={'Auth0' in tech ? tech.Auth0.picture : ''}
                            />
                          </ListItemAvatar>
                          <ListItemText
                            primary={tech.fieldData.Name_combined_ae}
                          ></ListItemText>
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </ListItem>
            </List>
          </Card>
        </>
      )}
      {contentLoading && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            textAlign: 'center',
            justifyContent: 'center',
            borderRadius: '0px'
          }}
        >
          <CircularProgress></CircularProgress>
        </div>
      )}
    </div>
  )
}
