import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import Upload from './Upload';
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import { Button, FormLabel, MenuItem, InputLabel } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import FormControl from '@material-ui/core/FormControl'
import { TextField } from '@material-ui/core'
// import lists from '../Individual/individual.module.css';
import { useAuth0 } from '../../react-auth0-wrapper'
import NumberFormat from 'react-number-format'
import LinearProgress from '@material-ui/core/LinearProgress'
import Select from '@material-ui/core/Select'
import { config } from '../../config'




const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap'
  },

  formControl: {
    width: '100%'
  },
  opportunities_Icon: {
    fontSize: '21px',
    marginLeft: '4px',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '2px'
    }
  },

  selectEmpty: {
    marginTop: theme.spacing(2)
  },

  paper: {
    minWidth: '35%',
    [theme.breakpoints.down('sm')]: {
      minWidth: '55%'
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: '90%',
      margin: '0 auto'
    }
  },

  action: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '0',
    padding: '0',
    borderBottom: '1px solid #acacac',
    marginBottom: '18px'
  },

  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '10px',
    color: theme.palette.grey[500],
    [theme.breakpoints.down('sm')]: {
      right: '11px',
      top: '10px'
    },
    [theme.breakpoints.down('xs')]: {
      right: '11px',
      top: '4px'
    }
  },

  generateAction: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '18px 24px',
    marginTop: '10px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'row'
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
      padding: '18px 24px'
    }
  },

  cancel: {
    width: '50%',
    color: '#fff',
    background: '#535b69',
    '&:hover': {
      background: '#494f5a'
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      marginBottom: '2%',
      marginLeft: '0'
    }
  },

  generate: {
    width: '50%',
    color: '#fff',
    background: '#24a0ed',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      margin: '8px',
      marginTop: '0'
    },

    '&:hover': {
      background: '#258fd2'
    }
  },

  dialogContentSpacing: {
    padding: '8px 24px',
    [theme.breakpoints.down('xs')]: {
      padding: '8px 24px'
    }
  },

  finalize_button: {
    marginTop: '0',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '4px'
    }
  }
}))



const SimplePdfDialog = props => {
  const { dialogOpen, setDialogOpen, selectedJobId } = props
  const classes = useStyles()
  const { getTokenSilently } = useAuth0()
  const [loading, setLoading] = useState(false)
  const [description, setDescription] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [selectedPdf, setSelectedPdf] = useState(null)

  const handleCloseClick = () => {
    setErrorMessage('')
    setDescription('')
    setDialogOpen(false)
  }
  let dialog
  if (dialogOpen) {
    dialog = props.description.Job_Description
    console.log(dialog, 'dialog')
  }

  const fetchCommand = async (url, data) => {
    console.log(JSON.stringify(data))
    console.log(pdfFile)
    console.log(url)
    const token = await getTokenSilently()
    const request = {
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + token,
        Accept: 'application/json'
      },
      body: data
    }
    const result = await fetch(url, request)
    const obj = await result.json()
    return obj
  }

  const onPdfSelect = event => {
    //Verify pdf file
    if (event.target.files.length > 0) {
      setSelectedPdf(event.target.files[0])
      console.log(selectedPdf)
    } else {
      setSelectedPdf(null)
    }
  }

  const [pdfFile, setPdfFile] = useState(null);
    const handleFileChange = (e) => {
      const pdfFile = e.target.files[0];
      setPdfFile(pdfFile);
    };

  const uploadPdf = async () => {
    setLoading(true)
    setErrorMessage('')
    const formData = new FormData();
    formData.append('job_id', selectedJobId)
    formData.append('description', description)
    formData.append('key', pdfFile.name)
    formData.append('file', pdfFile)
    try {
      // RENNEL PARINO HPDS 3/4/2024 - INSERT UPLOAD FUNCTION HERE
      // WITH THE CORRECT DATA PARAMETERS

      
      const path = config.API_ENDPOINT + '/api/uploadjobpdf'
      const result = await fetchCommand(path, formData)

      setLoading(false)

      if (result.message) {
        handleCloseClick()
        console.log(result.data.signUrl)        
        window.alert("Your PDF has successfully uploaded.")
        window.open(result.data.signUrl, '_blank')
        window.location.reload()
      } else {
        setErrorMessage('Error generating Simple Invoice.')
      }
    } catch (error) {
      setLoading(false)
      setErrorMessage('Error generating Simple Invoice.')
    }
  }

  return (
    <>
      <Dialog
        open={dialogOpen}
        onClose={handleCloseClick}
        aria-labelledby='max-width-dialog-title'
        classes={{ paper: classes.paper }}
      >
        <DialogActions className={classes.action}>
          <DialogTitle id='max-width-dialog-title'>
            Select PDF File
          </DialogTitle>

          {props.param === 'upload' && 
                <Upload 
                    selectedReport={props.selectedReport} 
                    selectedJob={props.selectedJob} 
                    dialogOpen={props.dialogOpen} 
                    setDialogOpen={props.setDialogOpen} 
                    setReloadPhotos={props.setReloadPhotos}
                />
            }


          <IconButton
            aria-label='close'
            className={classes.closeButton}
            onClick={handleCloseClick}
          >
            <CloseIcon />
          </IconButton>
        </DialogActions>

        {!loading &&  (
            <input
            accept='pdf/*'
            id='raised-button-file'
            type='file'
            onChange={handleFileChange}
          />
        )

        }

        {loading && (
          <DialogContent className={classes.dialogContentSpacing}>
            <LinearProgress />
          </DialogContent>
        )}

        <DialogActions className={classes.generateAction}>
          {!loading && (
            <>
              <Button
                className={classes.cancel}
                onClick={handleCloseClick}
                variant='contained'
                color='primary'
              >
                Cancel
              </Button>
              <Button
                className={classes.generate}
                color='secondary'
                variant='contained'
                onClick={uploadPdf}
              >
                Upload
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SimplePdfDialog
